//=require components/menu.js

(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
 
    ym(52199230, "init", {
         id:52199230,
         clickmap:true,
         trackLinks:true,
         accurateTrackBounce:true,
         webvisor:true
    });

$(document).ready(function () {
   
// Аккордион

var question = $('.faq-item__q');

question.click(function () {
    if (!($(this).parent().hasClass('active'))) {
        question.parent().removeClass('active');
        $(this).parent().addClass('active');
    } else {
        $(this).parent().removeClass('active');
    }
})

//Галерея

var thumb     = $(".portfolio-item > a"),
    nextBtn   = $(".overlay-btn__next"),
    prevBtn   = $(".overlay-btn__prev"),
    lightbox  = $(".lightbox"),
    targetImg = lightbox.find("img"),
    thumbIndex;

function replaceImg(src) {
    targetImg.attr("src", src);
}
function closeLightbox() {
    $('.modal:visible').fadeOut(700);
    $('.lightbox:visible').fadeOut(700);
    $('.overlay-btn-box:visible').fadeOut(600);
    $('.overlay').fadeOut(500);
}

thumb.click(function (e) {
    e.preventDefault();
    var photoLink = $(this).attr("href");
    replaceImg(photoLink)
    $('.overlay, .overlay-btn-box').fadeIn(500);
    lightbox.fadeIn(700);
    thumbIndex = $(this).parent().index();
})

nextBtn.click(function () {
    var nextImgSrc = thumb.parent().eq(thumbIndex + 1).find("a").attr("href");
    replaceImg(nextImgSrc);
    thumbIndex ++;
    if (thumbIndex == thumb.parent().length - 1) {
        thumbIndex = -1;
    }
})
prevBtn.click(function () {
    var prevImgSrc = thumb.parent().eq(thumbIndex - 1).find("a").attr("href");
    replaceImg(prevImgSrc);
    thumbIndex --;
    if (thumbIndex == 0) {
        thumbIndex = thumb.parent().length;
    }
})


// Модальное окно
var targetBtn, heightModal, widthModal;
var modelText  = "Вам нужна 3d-модель? Сделаем.",
    helpText   = "Нужна помощь с 3d-принтерем?",
    recallText = "Мы обязательно вам перезвоним.";

$('.sender').click(function () {
    targetBtn = $(this).attr('id');
    $('#target').val(targetBtn);
    heightModal = $('.modal').height();
    widthModal = $('.modal').width();
    replaceText(targetBtn);
    $('#phone').focus();
    $('.overlay').fadeIn(500);
    $('.modal').fadeIn(700);
});

$('.modal-close, .overlay').click(function () {
    closeLightbox();
})
$('body').keydown(function (e) {
    if (e.keyCode == 27) {
        closeLightbox();
    }
});

function replaceText(btn) {
    if (btn == "about-model") {
        $(".modal__h3 span").text(modelText);
    } else if (btn == "about-help") {
        $(".modal__h3 span").text(helpText);
    } else if (btn == "footer-recall") {
        $(".modal__h3 span").text(recallText);
    }; 
};

$("#send").click(function () {
    $.ajax({
        type: "POST",
        url: "mail.php",
        data: $(this).parent().serialize()  + '&' + window.location.search.substr(1)
    }).done(function() {
        $(this).parent().find("input").val("");
        $("#form1").trigger("reset");
        $('.modal').height(heightModal).width(widthModal);
        $('.modal__h3, .modal-form').fadeOut(700);
        $('.modal__tnx').delay(650).fadeIn(500);
    });
    return false;
})




















});








// let question = document.querySelectorAll('.faq-item__q');

// for (let i = 0; i < question.length; i++) {
//     question[i].onclick = function () {
//         if (!(this.parentElement.classList.contains('active'))) {
//             for (let j = 0; j < question.length; j++) {
//                 question[j].parentElement.classList.remove('active');
//             };
//             this.parentElement.classList.add('active')
//         } else {
//             this.parentElement.classList.remove('active')
//         }
//     }
// }

